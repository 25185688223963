export function getCookie(name) {
  const cookieName = name + "=";
  let decodedCookie;

  decodedCookie = document.cookie && decodeURIComponent(document.cookie);

  const cookieArray = decodedCookie && decodedCookie.split(";");
  if (cookieArray && cookieArray.length > 0)
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        const cookieValue = cookie.substring(cookieName.length, cookie.length);
        try {
          // Parse the cookie value as JSON and return
          return cookieValue ? JSON.parse(cookieValue) : "";
        } catch (error) {
          console.error("Error parsing cookie value:", error);
          return {};
        }
      }
    }
  return {};
}

export function deleteCookie(name, pathName) {
  document.cookie =
    name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;" + `path=${pathName}`;
}

export function setCookie(name, values, pathName) {
  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + 60 * 60 * 1000);
  document.cookie = `${name}=${JSON.stringify(
    values
  )}; expires=${expiryDate.toUTCString()}; path=${pathName}`;
}

export function deleteAllCookies() {
  // Get all cookies
  const cookies = document.cookie.split(";");

  cookies.forEach((cookie) => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim();
    
    // Delete cookie for the current path and also for the root path
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${window.location.pathname}`;
  });
}