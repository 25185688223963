import { Grid2, LinearProgress, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom"; // Import routing hooks

// Icons
import { BiArrowBack } from "react-icons/bi";

// Logos and Images
import MainLogo from "../assets/Logos/main-logo.svg";
import FitnessPlannerLogo from "../assets/Logos/Fitness Planner.svg";
import { getCookie } from "../Utils/cookie";

const Header = (props) => {
  const navigate = useNavigate(); // Get navigation function
  const location = useLocation(); // Get current location/route

  const backPage = () => {
    props?.goToPreviousSlide();
    // window.history.back();
  };

  const { gender } = getCookie("data");

  // Handle logo click
  const handleLogoClick = () => {
    if (location.pathname === "/thank-you") {
      navigate("/");
    }
  };

  return (
    <>
      <Grid2
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          width: "100%",
        }}
      >
        <Grid2
          className="header"
          sx={{
            justifyContent: props?.showSlideCount ? "space-between" : "center",
            width: "100%",
          }}
        >
          <Grid2>
            {props?.showBackButton && (
              <BiArrowBack
                size={20}
                color="white"
                style={{ cursor: "pointer" }}
                onClick={() => backPage()}
              />
            )}
          </Grid2>

          <Grid2
            className={"main-logo"}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <img
              src={MainLogo}
              alt="main-logo"
              onClick={handleLogoClick}
              style={{ cursor: location.pathname === "/thank-you" ? "pointer" : "default" }}
            />
            <div className="secondary-logo">
              <img 
                src={FitnessPlannerLogo} 
                alt="fitness-planner-logo" 
                onClick={handleLogoClick}
                style={{ cursor: location.pathname === "/thank-you" ? "pointer" : "default" }}
              />
            </div>
          </Grid2>
          <Grid2>
            {props?.showSlideCount && (
              <Typography color="white">{props?.showSlideCount}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2>
          {props?.showSlideCount && props?.progress && (
            <LinearProgress
              variant="determinate"
              value={props?.progress <= 100 ? props?.progress : 100}
              sx={{
                height: { md: "12px", sx: "9px" },
                backgroundColor: gender === "female" ? "#303266" : "#303266",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: gender === "female" ? "#d40180" : "#0143a0",
                  borderRadius: "6px",
                },
              }}
            />
          )}
        </Grid2>
      </Grid2>
    </>
  );
};

export default Header;