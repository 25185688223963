import { Suspense, lazy, useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./assets/css/style.scss";
import LoaderGif from "./Components/Loader";
import { GenderContext } from "./Context/GenderThemeContext";
import { ChangeTheme } from "./assets/css/GenderTheme";
import PaymentPopup from "./Components/PaymentPopup";
import ResetPassword from "./Pages/ResetPassword";

const AgeGroup = lazy(() => import("./Pages/AgeGroup"));
const Welcome = lazy(() => import("./Pages/Welcome"));
const Gender = lazy(() => import("./Pages/Gender"));
const MainGoal = lazy(() => import("./Pages/MainGoal"));
const BodyType = lazy(() => import("./Pages/BodyType"));
const BodyGoal = lazy(() => import("./Pages/BodyGoal"));
const BestShapeOfYourLife = lazy(() => import("./Pages/BestShapeOfYourLife"));
const FitnessLevel = lazy(() => import("./Pages/FitnessLevel"));
const TargetZone = lazy(() => import("./Pages/TargetZone"));
const WorkoutPlace = lazy(() => import("./Pages/WorkoutPlace"));
const EquipmentAccess = lazy(() => import("./Pages/EquipmentAccess"));
const WorkoutDays = lazy(() => import("./Pages/WorkoutDays"));
const WorkoutTime = lazy(() => import("./Pages/WorkoutTime"));
const WorkoutSchedule = lazy(() => import("./Pages/WorkoutSchedule"));
const DescribeYourDay = lazy(() => import("./Pages/DescribeYourDay"));
const PushUp = lazy(() => import("./Pages/PushUp"));
const Squats = lazy(() => import("./Pages/Squats"));
const WalkDaily = lazy(() => import("./Pages/WalkDaily"));
const SleepUsually = lazy(() => import("./Pages/SleepUsually"));
const WaterDrinkDaily = lazy(() => import("./Pages/WaterDrinkDaily"));
const Height = lazy(() => import("./Pages/Height"));
const Weight = lazy(() => import("./Pages/Weight"));
const GoalWeight = lazy(() => import("./Pages/GoalWeight"));
const Age = lazy(() => import("./Pages/Age"));
const WellnessProfile = lazy(() => import("./Pages/WellnessProfile"));
const UpcomingEvents = lazy(() => import("./Pages/UpcomingEvents"));
const EventDate = lazy(() => import("./Pages/EventDate"));
const PlanReady = lazy(() => import("./Pages/PlanReady"));
const GetEmail = lazy(() => import("./Pages/GetEmail"));
const PlanLoader = lazy(() => import("./Pages/PlanLoader"));
const PlanToGetInShape = lazy(() => import("./Pages/PlanToGetInShape"));
const Pricing = lazy(() => import("./Pages/Pricing"));
const Signup = lazy(() => import("./Pages/Signup"));
const ThankYou = lazy(() => import("./Pages/ThankYou"));
const Name = lazy(() => import("./Pages/Name"));
const GatherInformation = lazy(() => import("./Pages/GatherInformation"));

function App() {
  const { gender } = useContext(GenderContext); // Getting gender from context

  useEffect(() => {
    ChangeTheme(gender);
  }, [gender]);

  // remove below code once done
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <Suspense
      fallback={
        <>
          <LoaderGif />
        </>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<GatherInformation />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/gender" element={<Gender />} />
          <Route path="/age-group" element={<AgeGroup />} />
          <Route path="/main-goal" element={<MainGoal />} />
          <Route path="/body-type" element={<BodyType />} />
          <Route path="/body-goal" element={<BodyGoal />} />
          <Route
            path="/best-shape-of-your-life"
            element={<BestShapeOfYourLife />}
          />
          <Route path="/fitness-level" element={<FitnessLevel />} />
          <Route path="/target-zone" element={<TargetZone />} />
          <Route path="/workout-place" element={<WorkoutPlace />} />
          <Route path="/equipments-access" element={<EquipmentAccess />} />
          <Route path="/workout-days" element={<WorkoutDays />} />
          <Route path="/workout-time" element={<WorkoutTime />} />
          <Route path="/workout-schedule" element={<WorkoutSchedule />} />
          <Route path="/describe-your-day" element={<DescribeYourDay />} />
          <Route path="/pushup" element={<PushUp />} />
          <Route path="/squats" element={<Squats />} />
          <Route path="/walk-daily" element={<WalkDaily />} />
          <Route path="/sleep-usually" element={<SleepUsually />} />
          <Route path="/daily-water-drink" element={<WaterDrinkDaily />} />
          <Route path="/height" element={<Height />} />
          <Route path="/weight" element={<Weight />} />
          <Route path="/goal-weight" element={<GoalWeight />} />
          <Route path="/age" element={<Age />} />
          <Route path="/wellness-profile" element={<WellnessProfile />} />
          <Route path="/upcoming-events" element={<UpcomingEvents />} />
          <Route path="/event-date" element={<EventDate />} />
          <Route path="/ultimate-plan" element={<PlanToGetInShape />} />
          <Route path="/plan-loader" element={<PlanLoader />} />
          <Route path="/plan-ready" element={<PlanReady />} />
          <Route path="/email" element={<GetEmail />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/name" element={<Name />} />
          <Route path="/thank-you" element={<ThankYou />} />
          {/* <Route path="/information" element={<GatherInformation />} /> */}
          <Route path="/popup" element={<PaymentPopup />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
